<template>
  <div class="factory-container" id="autofitBox">
    <div class="factory-header">
      <div class="layout-header-logo">
        <img v-if="config && config.logo" :src="`//${domains.trans}/station/file/logo?id=${config.logo}&auth=${token}`" :title="config.name" />
      </div>
      <div class="layout-header-content">
        <div class="header-content-area">
          <div class="header-button-area">
            <div class="header-button-item" :style="{backgroundImage: `url(${currentItem == 'home' ? img.light.factory.index.header.button.active : img.light.factory.index.header.button.common})`}" @click="switchMenu('home')">首页</div>
            <div class="header-button-item" :style="{backgroundImage: `url(${currentItem == 'spread' ? img.light.factory.index.header.button.active : img.light.factory.index.header.button.common})`}" @click="switchMenu('spread')">设备分布</div>
            <div class="header-button-item" :style="{backgroundImage: `url(${currentItem == 'adjust' ? img.light.factory.index.header.button.active : img.light.factory.index.header.button.common})`}" @click="switchMenu('adjust')">智能控制</div>
            <div class="header-button-item" :style="{backgroundImage: `url(${currentItem == 'lux' ? img.light.factory.index.header.button.active : img.light.factory.index.header.button.common})`}" @click="switchMenu('lux')">光照度</div>
            <div class="header-button-item" :style="{backgroundImage: `url(${currentItem == 'config' ? img.light.factory.index.header.button.active : img.light.factory.index.header.button.common})`}" @click="switchMenu('config')">设备配置</div>
            <div class="header-button-item" :style="{backgroundImage: `url(${currentItem == 'analysis' ? img.light.factory.index.header.button.active : img.light.factory.index.header.button.common})`}" @click="switchMenu('analysis')">数据分析</div>
            <div class="header-button-item" :style="{backgroundImage: `url(${currentItem == 'system' ? img.light.factory.index.header.button.active : img.light.factory.index.header.button.common})`}" @click="switchMenu('system')">系统管理</div>
          </div>
          <!-- <div class="header-time-area">
            <div>{{date}}</div>
            <div>{{weeks[week]}} {{time}}</div>
          </div> -->
          <div class="header-sys-info">
            <div class="header-user-info">
              <img style="width: 30px; height: 30px; margin-right: 3px" :src="img.headerUserIcon" />
              <HeaderDropDown />
            </div>
            <div class="header-time-area">
              <!-- <div></div> -->
              <div>{{date}} {{weeks[week]}} {{time}}</div>
            </div>
          </div>
        </div>
        <div class="header-content-line">
          <img :src="img.light.factory.index.header.line.down" />
        </div>
      </div>
    </div>
    <div class="factory-content">
      <component :is="url" :key="newKey"></component>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import HeaderDropDown from '@/views/common/menu/HeaderDropDown'
import home from './Home'
import spread from './spread/Index'
import adjust from './adjust/Index'
import config from './config/Index'
import analysis from '../../road/analyse/Index'
import lux from '../../road/lux/Index'
import system from '../../../sys/Index.vue'
// import autofit from 'autofit.js'
export default {
  name: 'Home',
  components: {
    HeaderDropDown,
  },
  data() {
    return {
      date: '',
      time: '',
      week: '',
      currentItem: 'home',
      url: home,
      urls: {
        home: home,
        spread: spread,
        adjust: adjust,
        lux: lux,
        config: config,
        analysis: analysis,
        system: system,
      },
      timeout: null,
      newKey:0,
    }
  },
  watch: {
    agentChangeValue() {
      this.debounceInitData();
    },
    // $route: {
    //   handler: function (route) {
    //    console.log(route)
    //    // this.currentItem = route.path.split('/', 5)[4];
    //   },
    //   immediate: true
    // },
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('common', ['weeks']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
  },
  created: function () {
  },
  mounted: function () {
    this.setDateTime();
    // this.setAutofit();
  },
  methods: {
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.initData, 500);
    },
    initData: function () {
      if (this.customerId == 0) return;
      this.$store.dispatch('group/getDevPoses');
      this.$store.dispatch('group/getGroupImages');
    },
    setDateTime: function () {
      let now = new Date();
      this.date = now.format('yyyy年MM月dd日');
      this.time = now.format('HH:mm:ss');
      this.week = now.getDay();
      setTimeout(this.setDateTime, 1000);
    },
    switchMenu: function (menu) {
      if (this.currentItem == menu) return;
      this.currentItem = menu;
      this.url = this.urls[menu];
      this.newKey++;
    },
  },
}
</script>
<style scoped>
.factory-container {
  width: 100%;
  height: 100%;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  /* line-height: 0; */
}
.factory-header {
  height: 92px;
  flex: none;
  display: flex;
  padding: 0;
  background-color: #011220;
}
.factory-content {
  height: 100%;
  flex: auto;
}
.factory-content div {
  height: 100%;
}
.layout-header-side {
  width: 650px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.header-line-area {
  height: 43px;
  flex: none;
  text-align: right;
}
.header-time-area {
  position: absolute;
  right: 0;
  bottom: 0;
  /* border: solid 1px red; */
  /* word-break: keep-all; */
  white-space: nowrap;
  /* width: 267px; */
  flex: none;
  display: flex;
  padding: 3px 10px 5px 0px;
  /* flex-direction: column; */
  /* justify-content: flex-end;
  align-items: flex-end; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
}
.header-button-area {
  width: 0;
  flex: auto;
  /* border: solid 1px red; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.header-button-right {
  width: 0;
  flex: auto;
  /* border: solid 1px red; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.layout-header-logo {
  width: 700px;
  flex: none;
  /* text-align: center; */
  /* border: solid 1px red; */
  margin-left: 20px;
}
.layout-header-logo img {
  width: 700px;
  height: 92px;
}
.layout-header-content {
  width: 100px;
  flex: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;
}
.header-content-area {
  height: 0;
  flex: auto;
  display: flex;
  justify-content: flex-start;
  /* position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  height: 50px; */
}
.header-content-line {
  height: 30px;
  flex: none;
}
.header-sys-info {
  /* border: solid 1px red; */
  width: 150px;
  flex: none;
  padding-top: 5px;
  /* margin: auto 9px; */
  display: flex;
  flex-direction: column;
  position: relative;
  height: 65px;
  /* justify-content: flex-end;
  align-items: center; */
}
.header-user-info {
  /* border: solid 1px red; */
  width: 150px;
  flex: none;
  /* margin: auto 9px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-time-info {
  border: solid 1px red;
  width: 150px;
  flex: none;
  /* margin: auto 9px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-button-item {
  width: 107px;
  height: 43px;
  text-align: center;
  line-height: 43px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: auto 15px;
  color: #d1fcfd;
  cursor: pointer;
}
</style>