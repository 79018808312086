var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alarm-rule-container"},[_c('div',{staticClass:"query-data-area"},[_c('AuthButton',{staticStyle:{"margin":"0 5px"},attrs:{"opCode":"la","size":"default","type":"primary"},on:{"click":_vm.importLampNew}},[_vm._v("批量导入")]),_c('AuthButton',{staticStyle:{"margin":"0 5px"},attrs:{"opCode":"ld","size":"default","type":"error"},on:{"click":_vm.deleteParams}},[_vm._v("批量删除")]),_c('AuthButton',{staticStyle:{"margin":"0 5px"},attrs:{"opCode":"lv","size":"default","type":"primary"},on:{"click":_vm.exportLamp}},[_vm._v("批量导出")]),_c('input',{ref:"fileInput",attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}}),_c('AuthButton',{staticStyle:{"margin":"0 5px"},attrs:{"opCode":"le","size":"default","type":"primary"},on:{"click":_vm.importLampData}},[_vm._v("导入编辑数据")])],1),_c('div',{ref:"table1",staticClass:"alarm-data-area"},[_c('vxe-table',{key:_vm.dataRefresh,ref:"chkTable",attrs:{"border":"","resizable":"","show-overflow":"","show-header-overflow":"","keep-source":"","data":_vm.list,"height":_vm.tabHeight,"row-config":{isHover: true},"checkbox-config":{checkField: 'checked',}},on:{"checkbox-change":_vm.selectChangeEvent}},[_c('vxe-column',{attrs:{"width":"90","type":"checkbox","field":"checkbox","fixed":"left","title":"全选"}}),_c('vxe-column',{attrs:{"field":"name","title":"名称","width":"150","header-align":"center","fixed":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"stationName","title":"所在分区","width":"250","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.groupName)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"poleName","title":"所在灯杆","width":"150","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.poleName)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"areaName","title":"所在分组","width":"150","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.areaName)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"code","title":"通信ID","width":"150","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.code)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"deviceTypeName","title":"设备类型","width":"250","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.deviceTypeName)+" ")]}}])}),_c('vxe-colgroup',{attrs:{"title":"控制方式","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"mode","title":"模式","header-align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.controlModes[row.content.mode])+" ")]}}])}),_c('vxe-column',{attrs:{"field":"timeName1","title":"计时日表","header-align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.timeName1)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"timeName2","title":"准时日表","header-align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.timeName2)+" ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":"电压监测","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"ur","title":"额定值","header-align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.ur)+" V ")]}}])}),_c('vxe-column',{attrs:{"field":"uh","title":"上限值","header-align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.uh)+" % ")]}}])}),_c('vxe-column',{attrs:{"field":"ul","title":"下限值","header-align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.ul)+" % ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":"一路控制通道","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"en1","title":"启用","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.en1 ? '启用' : '禁用')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"nm1","title":"名称","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.nm1)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"pr1","title":"额定功率","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.pr1)+" W ")]}}])}),_c('vxe-column',{attrs:{"field":"lc1","title":"灯具数","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.lc1)+" ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":"二路控制通道","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"en2","title":"启用","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.en2 ? '启用' : '禁用')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"nm2","title":"名称","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.nm2)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"pr2","title":"额定功率","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.pr2)+" W ")]}}])}),_c('vxe-column',{attrs:{"field":"lc2","title":"灯具数","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.lc2)+" ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":"三路控制通道","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"en3","title":"启用","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.en3 ? '启用' : '禁用')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"nm3","title":"名称","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.nm3)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"pr3","title":"额定功率","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.pr3)+" W ")]}}])}),_c('vxe-column',{attrs:{"field":"lc3","title":"灯具数","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.lc3)+" ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":"四路控制通道","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"en4","title":"启用","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.en4 ? '启用' : '禁用')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"nm4","title":"名称","header-align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.nm4)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"pr4","title":"额定功率","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.pr4)+" W ")]}}])}),_c('vxe-column',{attrs:{"field":"lc4","title":"灯具数","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.lc4)+" ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":"漏电阈值","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"ll","title":"一级","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.ll)+" mA ")]}}])}),_c('vxe-column',{attrs:{"field":"lh","title":"二级","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.lh)+" mA ")]}}])}),_c('vxe-column',{attrs:{"field":"lx","title":"三级","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.lx || '-')+" mA ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":"水浸监测通道","header-align":"center"}},[_c('vxe-column',{attrs:{"field":"l1","title":"一级","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.l1)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"l2","title":"二级","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.l2)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"l3","title":"三级","header-align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.content.l3)+" ")]}}])})],1),_c('vxe-column',{attrs:{"width":"120","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"le"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("编辑")]),_c('AuthButton',{attrs:{"opCode":"ld","type":"error"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])},[_c('template',{slot:"header"},[_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"la"},on:{"click":_vm.handleAdd}},[_vm._v("新增")]),_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"lv"},on:{"click":_vm.getList}},[_vm._v("刷新")])],1)],2)],1),_c('ModalLampEdit',{attrs:{"item":_vm.editItem,"areas":_vm.areas,"gates":_vm.gates,"groups":_vm.selectedGroups},on:{"saved":_vm.itemSaved},model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}}),_c('ModalLampImportNew',{attrs:{"item":_vm.editItem,"areas":_vm.areas,"gates":_vm.gates,"groups":_vm.selectedGroups},on:{"saved":_vm.itemSaved},model:{value:(_vm.showImportModalNew),callback:function ($$v) {_vm.showImportModalNew=$$v},expression:"showImportModalNew"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }