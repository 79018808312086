var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-common-container"},[_c('div',{ref:"table",staticClass:"alarm-data-area"},[_c('vxe-table',{key:_vm.dataRefresh,ref:"chkTable",attrs:{"border":"","resizable":"","show-overflow":"","show-header-overflow":"","keep-source":"","data":_vm.devices,"height":_vm.tabHeight,"row-config":{isHover: true},"checkbox-config":{checkField: 'checked',},"merge-cells":_vm.mergeData},on:{"checkbox-change":_vm.selectChangeEvent}},[_c('vxe-column',{attrs:{"width":"50","type":"checkbox","field":"checkbox","fixed":"left"}}),_c('vxe-column',{attrs:{"field":"name","title":"名称","fixed":"left","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getSmallTypeName(row.content.stid))+" ")]}}])}),_c('vxe-column',{attrs:{"field":"deviceTypeName","title":"协议类型","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.deviceTypeName)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"ch","title":"通信ID","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.deviceCodeName(row.code))+" ")]}}])}),_c('vxe-column',{attrs:{"field":"online","title":"光控监测","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(!row.content.enlux ? '未启用':("采集间隔时间" + (row.content.tlux) + "秒"))+" ")]}}])}),_c('vxe-column',{attrs:{"field":"result","title":"执行结果","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCmdStatus(row))+" ")]}}])})],1)],1),_c('ModalArgEdit',{attrs:{"command":_vm.cmdItem,"areas":_vm.areas},on:{"saved":_vm.argsSaved},model:{value:(_vm.showArgModal),callback:function ($$v) {_vm.showArgModal=$$v},expression:"showArgModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }