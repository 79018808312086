import { render, staticRenderFns } from "./manyImg.vue?vue&type=template&id=d58d2e68&scoped=true&"
import script from "./manyImg.vue?vue&type=script&lang=js&"
export * from "./manyImg.vue?vue&type=script&lang=js&"
import style0 from "./manyImg.vue?vue&type=style&index=0&id=d58d2e68&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d58d2e68",
  null
  
)

export default component.exports