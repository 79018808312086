<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')"
        style="width: 140px; margin-right: 10px;"></Input>
      {{showLang('com.export.cn.connid')}}：
      <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')"
        style="width: 140px; margin-right: 10px;"></Input>
      {{showLang('com.device.pole.name')}}:
      <Input type="text" v-model="filter.poleId" clearable :placeholder="showLang('com.device.pole.name')"
        style="width: 140px; margin-right: 10px;"></Input>
      {{showLang('com.group')}}：
      <Select :placeholder="showLang('save.select')" v-model="filter.lampGroupId"
        style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in areas">
          <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
        </template>
      </Select>
      {{showLang('com.lamp.type')}}:
      <Select :placeholder="showLang('save.select')" v-model="filter.typeId" style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(v, k) in newLightTypes">
          <Option :value="k" :key="k">{{v}}</Option>
        </template>
      </Select>
      {{showLang('com.export.cn.type')}}:
      <!--  -->
      <Select :placeholder="showLang('save.select')" v-model="filter.smallTypeId"
        style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(item, idx) in smallTypes">
            <Option v-if="item.type == 3" :value="item.code" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
      {{showLang('com.ins.timeTable')}}:
      <Select :placeholder="showLang('save.select')" v-model="filter.timeId" style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in timeTables">
          <Option :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
        </template>
      </Select>
      <Button v-if="funCodes('lv')" size="default" type="primary" @click="getList"
        style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>

      <Button type="success" :loading="exportLoading" @click="exportData"
        style="margin-right: 10px;">{{showLang('com.op.export')}}</Button>
      <!-- {{showLang('com.data.sum.record',list.length)}} -->
    </div>
    <div class="query-data-area">
      <Button v-if="funCodes('la')" size="default" type="primary" @click="importLampNew"
        style="margin: 0 5px;">{{showLang('import.batch')}}</Button>
      <template v-for="(btn, idx) in btns">
        <Button v-if="funCodes('le')" size="default" :key="idx" type="primary" @click="docmd(btn)"
          style="margin: 2px 5px;">{{showLang(btn.lang)}}</Button>
      </template>
      <Dropdown>
        <Button type="primary">
          {{showLang('com.data.re.energy.count')}}
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <template #list>
          <Form :model="formRepair" :label-width="120" style="margin-right: 30px; margin-top: 30px;">
            <FormItem label="修复起始日期">
              <DatePicker type="date" :placeholder="showLang('filter.time.start')" v-model="formRepair.start">
              </DatePicker>
            </FormItem>
            <FormItem label="修复截止日期">
              <DatePicker type="date" :placeholder="showLang('filter.time.end')" v-model="formRepair.end"></DatePicker>
            </FormItem>
            <FormItem :label-width="0">
              <Button v-if="funCodes('la')" size="default" type="primary" @click="repairStationEnergy"
                style="margin-left: 100px">{{showLang('com.data.re.energy.count')}}</Button>
            </FormItem>
          </Form>
        </template>
      </Dropdown>
      <Button v-if="funCodes('ld')" size="default" type="error" @click="deleteParams"
        style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}" @checkbox-all="selectAllEvent"
        :seq-config="{startIndex: (filter.index-1)*filter.size}" row-id="id">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="150" fixed="left" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="stationName" :title="showLang('com.site')" width="250" header-align="center">
          <template #default="{ row }">
            {{row.stationName}}
          </template>
        </vxe-column>
        <vxe-column field="outputName" :title="showLang('com.contact.outout.need')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.outputName}}
          </template>
        </vxe-column>
        <vxe-column field="poleName" :title="showLang('com.export.cn.pole.relate')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.poleName}}
          </template>
        </vxe-column>
        <vxe-column field="groupName" :title="showLang('com.export.cn.group.relate')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.groupName}}
          </template>
        </vxe-column>
        <vxe-column field="code" :title="showLang('com.export.cn.connid')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column field="type" :title="showLang('com.lamp.type')" width="150" header-align="center">
          <template #default="{ row }">
            {{newLightTypes[row.content.type]}}
          </template>
        </vxe-column>
        <vxe-column field="deviceTypeName" :title="showLang('com.export.cn.type')" width="250" header-align="center">
          <template #default="{ row }">
            {{row.deviceTypeName}}
          </template>
        </vxe-column>
        <vxe-column field="timeName" :title="showLang('com.ins.timeTable')" width="250" header-align="center">
          <template #default="{ row }">
            {{row.timeName}}
          </template>
        </vxe-column>
        <vxe-colgroup :title="showLang('com.lighting.V')" header-align="center">
          <vxe-column field="enu" :title="showLang('alarm.enable')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.enu ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
          <vxe-column field="uh" :title="showLang('com.data.upper.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.uh}} V
            </template>
          </vxe-column>
          <vxe-column field="ul" :title="showLang('com.data.lower.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.ul}} V
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup :title="showLang('com.lighting.I')" header-align="center">
          <vxe-column field="enc" :title="showLang('alarm.enable')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.enc ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
          <vxe-column field="ch" :title="showLang('com.data.upper.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.ch}} A
            </template>
          </vxe-column>
          <vxe-column field="cl" :title="showLang('com.data.lower.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.cl}} A
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup :title="showLang('com.lighting.T')" header-align="center">
          <vxe-column field="ent" :title="showLang('alarm.enable')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.ent ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
          <vxe-column field="th" :title="showLang('com.data.upper.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.th}} ℃
            </template>
          </vxe-column>
          <vxe-column field="tl" :title="showLang('com.data.lower.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.tl}} ℃
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup :title="showLang('com.lighting.cl')" header-align="center">
          <vxe-column field="enleac" :title="showLang('alarm.enable')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.enleac ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
          <vxe-column field="leac" :title="showLang('com.data.upper.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.leac}} mA
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup :title="showLang('com.lighting.lu')" header-align="center">
          <vxe-column field="enleav" :title="showLang('alarm.enable')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.enleav ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
          <vxe-column field="leav" :title="showLang('com.data.upper.val')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.content.leav}} V
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-column field="engyro" :title="showLang('alarm.type.gyro')" header-align="center" width="90">
          <template #default="{ row }">
            {{row.content.engyro ? showLang('com.state.enable') : showLang('com.state.disable')}}
          </template>
        </vxe-column>
        <vxe-colgroup :title="showLang('com.ope.nav.channel.1')" header-align="center">
          <vxe-column field="en1" :title="showLang('com.state.enable')" header-align="center" width="60">
            <template #default="{ row }">
              {{row.content.en1 ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
          <vxe-column field="nm1" :title="showLang('com.tab.title')" header-align="center" width="60">
            <template #default="{ row }">
              {{row.content.en1 ? row.content.nm1 : '-'}}
            </template>
          </vxe-column>
          <vxe-column field="pr1" :title="showLang('com.lighting.pr1')" header-align="center" width="100">
            <template #default="{ row }">
              {{row.content.en1 ? row.content.pr1 : '-'}} W
            </template>
          </vxe-column>
          <vxe-column field="lp1" :title="showLang('com.import.cn.lamp.p')" header-align="center" width="100">
            <template #default="{ row }">
              {{row.content.en1 ? row.content.lp1 : '-'}} W
            </template>
          </vxe-column>
          <vxe-column field="lc1" :title="showLang('com.import.cn.lamp.num')" header-align="center" width="100">
            <template #default="{ row }">
              {{row.content.en1 ? row.content.lc1 : '-'}}
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup :title="showLang('com.ope.nav.channel.2')" header-align="center">
          <vxe-column field="en2" :title="showLang('com.state.enable')" header-align="center" width="60">
            <template #default="{ row }">
              {{row.content.en2 ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
          <vxe-column field="nm2" :title="showLang('com.tab.title')" header-align="center" width="60">
            <template #default="{ row }">
              {{row.content.en2 ? row.content.nm2 : '-'}}
            </template>
          </vxe-column>
          <vxe-column field="pr2" :title="showLang('com.lighting.pr1')" header-align="center" width="100">
            <template #default="{ row }">
              {{row.content.en2 ? row.content.pr2 : '-'}} W
            </template>
          </vxe-column>
          <vxe-column field="lp2" :title="showLang('com.import.cn.lamp.p')" header-align="center" width="100">
            <template #default="{ row }">
              {{row.content.en2 ? row.content.lp2 : '-'}} W
            </template>
          </vxe-column>
          <vxe-column field="lc2" :title="showLang('com.import.cn.lamp.num')" header-align="center" width="100">
            <template #default="{ row }">
              {{row.content.en2 ? row.content.lc2 : '-'}}
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-column width="160" fixed="right">
          <template slot="header">
            <AuthButton opCode='la' style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</AuthButton>
            <AuthButton opCode='lv' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}
            </AuthButton>
          </template>
          <template #default="{ row }">
            <AuthButton opCode='le' style="margin-right: 5px" @click="handleEdit(row)">{{showLang('com.op.edit')}}
            </AuthButton>
            <AuthButton opCode='ld' type="error" @click="handleDelete(row)">{{showLang('com.op.del')}}</AuthButton>
          </template>
        </vxe-column>
      </vxe-table>
      <p>
        <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
          :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange">
        </vxe-pager>
      </p>
      <ModalLampEdit v-model="showEditModal" :item="editItem" :areas="areas" :stations="selectedStations"
        @saved="itemSaved" />
      <ModalLampImportNew v-model="showImportModalNew" :item="editItem" :areas="areas" :stations="selectedStations"
        @saved="itemSaved" />
      <ModalEditArgs v-model="showArgModal" :item="editItem" :areas="areas" @saved="argsSaved" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLampEdit from './ModalLampEdit'
import ModalLampImportNew from './ModalLampImportNew'
import ModalEditArgs from './ModalEditArgs'
export default {
  name: 'ConfigLampIndex',
  components: {
    ModalLampEdit,
    ModalLampImportNew,
    ModalEditArgs,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      pageSizes:[20,100,500,1000,5000],
      showImportModalNew: false,
      showImportModalOld: false,
      exportLoading: false,
      dataRefresh: 0,
      filter: {
        "groupId": 0,
        "stationId": 0,
        "name": "",
        "code": "",
        "lampGroupId": 0,
        "typeId": 0,
        "poleId": '',
        "smallTypeId": 0,
        "timeId": 0,
        "size": 20,//20 100 500 1000 5000
        "index": 1,
      },
      total: 0,
      exportExcel: {
        data: [],
        isAll: false,
      },
      form: {
        name: '',
        areaId: 0,
      },
      timeTables: [],//时间表
      areas: [],
      btns: [
        { code: 'updateLightOutput', name: '关联控制输出',lang:'com.ope.association.ch' },
        { code: 'updateControlChannel', name: '设定控制通道',lang:'com.ins.set.updateControlChannel' },
        { code: 'updateLightGroup', name: '设定分组',lang:'com.ins.set.updateLightGroup' },
        { code: 'updateLightType', name: '设定类型',lang:'com.ins.set.updateLightType' },
        { code: 'updateLightTimeTable', name: '设定时间表',lang:'com.ins.set.updateLightTimeTable' },
        { code: 'updateLeakageRate', name: '设定漏电阈值',lang:'com.ins.set.updateLeakageRate' },
        // { code: 'updateWaterRate', name: '设定水浸阈值',lang:'com.ins.set.updateWaterRate' },
        { code: 'updateTempRate', name: '设定温度阈值',lang:'com.ins.set.updateTempRate' },
        { code: 'updateVoltageRate', name: '设定电压阈值',lang:'com.ins.set.updateVoltageRate' },
        { code: 'updateLightEnableAlarm', name: '设定电流阈值',lang:'com.ins.set.updateCurrentRate' },
      ],
      formRepair: {
        start: '',
        end: '',
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'newLightTypes', 'deviceTypes' ,'smallTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.filter.index=1;
      this.getList();
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    let now = new Date();
    let monthFirst = now.monthFirstDay();
    monthFirst.setMonth(monthFirst.getMonth() - 1);
    this.formRepair.start = monthFirst.format('yyyy-MM-dd');
    let monthLast = monthFirst.monthLastDay();
    this.formRepair.end = monthLast.format('yyyy-MM-dd');
    this.queryAreas();
    this.getList();
    this.getUseablePlan();
  },
  destroyed: function () {
  },
  methods: {
    getDeviceName: function (item) {
      return `${item.name}[${this.deviceTypes[item.type]}][ID:${item.code}]`
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList(true)
    },
    selectAllEvent({ checked }) {//全选
      this.exportExcel.isAll = checked;
      if (!checked) {
        this.exportExcel.data = [];
      }
    },
    checkRowKeys() {//默认选中
      let table = this.$refs.chkTable;
      this.exportExcel.data.forEach(el => {
        this.list.forEach((ele) => {
          if (ele.id == el.id) {
            table.setCheckboxRow(table.getRowById(ele.id), true);
          }
        });
      })
      this.dataRefresh++;
    },
    exportData: function () {
      if (!this.exportExcel.isAll && this.exportExcel.data.length == 0) {
        this.$Message.warning(this.showLang('com.export.err.record'));
        return;
      }
      let ajaxData = {
        "groupId": 0,
        "stationId": 0,
        "name": this.filter.name,
        "code": this.filter.code,
        "lampGroupId": this.filter.lampGroupId,
        "poleId": this.filter.poleId,
        "smallTypeId": this.filter.smallTypeId,
        "typeId": this.filter.typeId,
        "timeId": this.filter.timeId,
        "isAll": this.exportExcel.isAll,
        "ids": [],
      }
      if (this.selectedNode.type == this.productCodes.station) {
        ajaxData.stationId = this.selectedNode.id;
      } else {
        ajaxData.groupId = this.selectedNode.id;
      }
      this.exportExcel.data.forEach(el => {
        ajaxData.ids.push(el.id)
      })
      this.$axios.request({
        url: `//${this.domains.trans}/station/config/ExportLampsByFilter`,
        method: 'post',
        data: ajaxData,
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${this.showLang('export.light.file.name')}.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    docmd: function (params) {
      // console.log('do cmd', params)
      if(params.code == 'updateLightOutput'){
        if(this.selectedNode.type != this.productCodes.station){
          this.$Message.warning(this.showLang('com.save.err.station'));
          return;
        }
        params.stationId = this.selectedNode.id;
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      this.editItem = params;
      this.showArgModal = true;
    },
    argsSaved: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$axios.post(`//${this.domains.trans}/station/config/BatchEditLight`, {
        list, code: params.code, args: params.args
      }).then(res => {
        if (res.code == 0) {
          this.getList();
        }
      });
    },
    queryAreas: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'areas', res.data);
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex);
      let leng = this.exportExcel.data.filter((el) => el.id == this.list[rowIndex].id)
      if (leng.length == 0 && this.list[rowIndex].checked) {
        this.exportExcel.data.push(this.list[rowIndex])
      }
      if(!this.list[rowIndex].checked && leng.length >0){
        this.exportExcel.data= this.exportExcel.data.filter(item=>item.id!==this.list[rowIndex].id)
      }
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 60;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: [params.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    repairStationEnergy: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.site.energy'),
        onOk: async () => {
          let start = new Date(this.formRepair.start).format('yyyy-MM-dd');
          let end = new Date(this.formRepair.end).format('yyyy-MM-dd');
          this.$axios.post(`//${this.domains.trans}/third/test/RepairStationBeforeEnergy`, { groupId, stationId, start, end }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.data.count.record', res.data));
            }
          });
        }
      });
    },
    getList: function (is) {
      if (is && !is) {
        this.exportExcel.data = [];
      }
      this.filter.groupId = 0, this.filter.stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        this.filter.stationId = this.selectedNode.id;
      } else {
        this.filter.groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryLampsByFilter`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
          setTimeout(() => {
            this.checkRowKeys();
          }, 100);
          this.dataRefresh++;
        }
      });
    },
    importLampNew: function () {
      this.item = {};
      this.showImportModalNew = true;
    },
    importLampOld: function () {
      this.$Modal.info({
        title: this.showLang('com.tips.function'),
        content: this.showLang('com.tips.function.con'),
      })
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  align-items: center;
  margin: 5px 0;
  padding: 5px 0;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>